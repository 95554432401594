import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import LeadTokenValidator from './components/LeadTokenValidator';
import RedirectPage from './components/RedirectPage';
import LoginScreen from './components/Auth/LoginScreen';
import PrivateRoute from './components/PrivateRoute'; // Corrected import statement
import LeadForm from './components/Lead/LeadForm';
import RecoverPassword from './components/Auth/RecoverPassword';
import ProcessDocuments from './components/Documents/ProcessDocuments';
import Dashboard from './components/Dashboard/Dashboard';
import NotFoundPage from './components/404';
import { DocumentsWrapper } from './components/Documents/DocumentWrapper';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function App() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then(registration => {
          // console.log('SW registered: ', registration);
        })
        .catch(registrationError => {
          // console.log('SW registration failed: ', registrationError);
        });
    });
  }

  return (
    <Router basename="/">
      {/* Setting a basename if your app is not at the root */}
      <AuthProvider>
        <Routes>
          <Route path="/" element={<HomeRedirect />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/redirect" element={<RedirectPage to="/" />} />
          <Route path="/validate-token" element={<LeadTokenValidator />} />
          <Route path="/lead" element={<LeadFormWrapper />} />
          <Route path="/recuperar" element={<RecoverPassword />} />
          <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/documentos" element={<DocumentsWrapper />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

const HomeRedirect = () => {
  const query = useQuery();
  const token = query.get('token');
  if (token) {
    return <RedirectPage to={`/lead?token=${token}`} />;
  } else {
    return <RedirectPage to="/login" />;
  }
};

const LeadFormWrapper = () => {
  const query = useQuery();
  const token = query.get('token');
  return <LeadForm leadToken={token} />;
};

export default App;