import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { getUserWithExpiry } from '../../services/authUtils';

import apiService from '../../services/apiService';
import NavBar from '../Common/NavBar';
import CopyrightInfo from '../Common/CopyrightInfo';
import ContactBanner from '../Common/ContactBanner';
import ProcessCard from '../Process/ProcessCard';
import PwaInstallPrompt from '../Common/PWAInstallPrompt';

const Dashboard = () => {
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [processData, setProcessData] = useState(null);

    useEffect(() => {
        // GTM Event: Dashboard Loaded
        window.dataLayer.push({
            event: 'dashboard_page_view',
            page: 'Dashboard'
        });

        const fetchProcessData = async () => {
            const user = getUserWithExpiry();
            if (!user) {
                navigate('/login');
                return;
            }
            setLoading(true);
            try {
                const initialData = await apiService.getProcessID(user.input, user.accessToken);
                if (initialData && initialData.proc_processes && initialData.proc_processes.length > 0) {
                    const processId = initialData.proc_processes[0].id;
                    const detailedProcessData = await apiService.fetchProcessDetails(processId);
                    localStorage.setItem('processId', processId);
                    setProcessData(detailedProcessData);  // Set processData as an object

                    // GTM Event: Process Data Fetched Successfully
                    window.dataLayer.push({
                        event: 'process_data_fetched',
                        processId: processId,
                        status: 'success'
                    });

                    console.log("Fetched process data:", detailedProcessData);
                } else {
                    setError('No process data found.');

                    // GTM Event: No Process Data Found
                    window.dataLayer.push({
                        event: 'process_data_not_found',
                        status: 'no_data'
                    });
                }
            } catch (error) {
                console.error('Error fetching process data:', error);
                setError('Failed to load process data.');
                navigate('/login');

                // GTM Event: Error Fetching Process Data
                window.dataLayer.push({
                    event: 'process_data_fetch_error',
                    status: 'error',
                    errorMessage: error.message
                });
            } finally {
                setLoading(false);
            }
        };
        fetchProcessData();
    }, [navigate]);

    const getGreeting = () => {
        const hour = new Date().getHours();
        if (hour < 12) return "Bom dia";
        if (hour < 20) return "Boa tarde";
        return "Boa noite";
    };

    return (
        <div>
            <NavBar />
            <PwaInstallPrompt />
            <div className="md:flex sm:block justify-center bg-amco-gold container-noNav">
                <div className="soft-bg roundContainer p-10 ml-5 mr-5 max-w-screen-xl md:w-full">
                    <h2 className="text-xl font-bold mobile-spacing">{getGreeting()}, {processData ? processData.nameClient : ''}</h2>
                    <p className="pb-8">Nesta área pode aceder a todos os seus processos ou iniciar um novo com apenas um clique.</p>
                    <hr />
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        processData ? (
                            <ProcessCard data={processData} />
                        ) : (
                            <p>No process data to display.</p>
                        )
                    )}
                    <div className="col-span-12">
                        <a
                            href="https://amco.pt"
                            target="_blank"
                            rel="noopener noreferrer"  // This adds security for external links
                        >
                            <button
                                type="button"
                                className="justify-center inline py-3 px-4 mt-4 border border-transparent rounded-md shadow-sm text-sm font-bold text-white amco-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:amco-blue/75 flex w-full"
                            >
                                Novo Pedido de Crédito
                            </button>
                        </a>
                    </div>
                    {error && <p className="text-red-500">{error}</p>}
                    <div className="hidden md:block">
                        <ContactBanner />
                    </div>
                    <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-0 mt-12">
                        <CopyrightInfo />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
