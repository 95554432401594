import React from 'react';
import PropTypes from 'prop-types';
import { MdOutlineLock, MdOutlinePersonAddAlt, MdOutlineExpandMore, MdCheckCircle, MdWarning } from "react-icons/md";
import { RxCrossCircled } from "react-icons/rx";

const StepThree = ({ formData, processData, apiData, getCreditTypeDescriptionById, onlySimulation, preApproval, addSecondHolder }) => {

    // Early exit if required data is missing
    if (!apiData || !formData || !processData) {
        return <p>Estamos a calcular a viabilidade do seu pedido de crédito. Aguarde por favor...</p>;
    }

    const calculateAge = (birthDate) => {
        const today = new Date();
        const birth = new Date(birthDate);
        let age = today.getFullYear() - birth.getFullYear();
        const monthDiff = today.getMonth() - birth.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
            age--;
        }
        return age;
    };

    const clientAge = formData.birthDate ? calculateAge(formData.birthDate) : null;

    // Scenario 1: Effort rate < 50 and Credit Type is 1 or 2 (Pre-approved)
    if (processData?.effortRate < 50 && (processData?.creditType === 1 || processData?.creditType === 2)) {
        return (
            <div className="space-y-4">
                <MdCheckCircle className="w-[100px] h-[auto] text-green-600 text-center ml-[auto] mr-[auto]" />
                <p className="font-bold text-xl text-green-600 text-center">O seu processo foi pré-aprovado!</p>

                <p className="text-center md:pl-20 md:pr-20 sm:pl-4 sm:pr-4">
                    O processo de <span className="font-semibold">{getCreditTypeDescriptionById(processData.creditType)}</span> solicitado, no valor de <span className="font-semibold">{processData.amount}€</span>, para o prazo de <span className="font-semibold">{processData.deadline} meses</span> cumpre todos os requisitos de pré-aprovação.
                    A sua prestação mensal tem um valor estimado de:
                </p>

                <p className="text-center text-5xl">{processData.startRenderAux}€*</p>
                <div className="disclaimer text-xs text-center">*Valor meramente indicativo e corresponde a um valor médio de simulação</div>

                <div className="mt-20 md:flex sm:block space-between form-group col-12 px-0 d-block">
                    <button type="button" onClick={onlySimulation} className="mobile-spacing md:mr-2 w-full py-2 px-4 border border-dark rounded-md shadow-sm text-sm font-medium text-dark bg-transparent hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                        Sem Interesse / Apenas Simulação
                    </button>
                    <button type="button" onClick={preApproval} className="md:ml-2 w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                        Avançar
                    </button>
                </div>
                <hr />
            </div>
        );
    }

    // Scenario 2: Effort rate < 50 but credit type is 3 or 4 (Additional info)
    if (processData?.effortRate < 50) {
        return (
            <div className="space-y-4">
                <MdWarning className="w-[100px] h-[auto] text-orange-600 text-center ml-[auto] mr-[auto]" />
                <p className="font-bold text-xl text-orange-600 text-center">Informação Adicional</p>

                <p className="text-center md:pl-20 md:pr-20 sm:pl-4 sm:pr-4">
                    O processo de <span className="font-semibold">{getCreditTypeDescriptionById(processData.creditType)}</span> que solicitou tem algumas especificidades.<br />
                    Um dos nossos gestores de crédito irá entrar em contacto consigo para discutir os próximos passos e assegurar que todos os documentos necessários são enviados.
                </p>

                <div className="mt-20 flex space-between form-group col-12 px-0 d-block">
                    <button type="button" onClick={onlySimulation} className="mr-2 w-full py-2 px-4 border border-dark rounded-md shadow-sm text-sm font-medium text-dark bg-transparent hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                        Voltar ao website da AMCO Intermediários de Crédito
                    </button>
                </div>
                <hr />
            </div>
        );
    }

    // Scenario 3: Effort rate > 50 with 1 client (Recommendation to add second holder)
    // if (processData?.effortRate > 50 && processData?.clients === 1) {
    //     return (
    //         <div className="space-y-4">
    //             <MdWarning className="w-[100px] h-[auto] text-orange-600 text-center ml-[auto] mr-[auto]" />
    //             <p className="font-bold text-xl text-orange-600 text-center">O seu processo foi recusado!</p>

    //             <p className="text-center md:pl-20 md:pr-20 sm:pl-4 sm:pr-4">
    //                 O processo de <span className="font-semibold">{getCreditTypeDescriptionById(processData.creditType)}</span> solicitado, no valor de <span className="font-semibold">{processData.amount}€</span>, para o prazo de <span className="font-semibold">{processData.deadline} meses</span> excede a sua taxa de esforço.
    //                 <span className="font-semibold"> Considere adicionar um segundo titular ao seu pedido para que o mesmo seja reavaliado.</span><br /><br />
    //             </p>

    //             <div className="mt-20 flex space-between form-group col-12 px-0 d-block">
    //                 <button type="button" onClick={addSecondHolder} className="ml-2 w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
    //                     Adicionar Segundo Titular
    //                 </button>
    //             </div>
    //             <hr />
    //         </div>
    //     );
    // }

    // Scenario 4: Effort rate > 50 with 2 clients (Contact recommendation)
    // if (processData?.effortRate > 50 && processData?.clients === 2) {
    //     return (
    //         <div className="space-y-4">
    //             <RxCrossCircled className="w-[100px] h-[auto] text-red-600 text-center ml-[auto] mr-[auto]" />
    //             <p className="font-bold text-xl text-red-600 text-center">O seu processo foi recusado!</p>

    //             <p className="text-center md:pl-20 md:pr-20 sm:pl-4 sm:pr-4">
    //                 O processo de <span className="font-semibold">{getCreditTypeDescriptionById(processData.creditType)}</span> solicitado, no valor de <span className="font-semibold">{processData.amount}€</span>, para o prazo de <span className="font-semibold">{processData.deadline} meses</span> foi recusado porque excede a sua taxa de esforço. Para saber como poderá reduzir a sua taxa de esforço entre em contacto connosco através de <a href="tel:912608364">+351 912 608 364</a>.
    //             </p>

    //             <div className="mt-20 flex space-between form-group col-12 px-0 d-block">
    //                 <button type="button" onClick={onlySimulation} className="mr-2 w-full py-2 px-4 border border-dark rounded-md shadow-sm text-sm font-medium text-dark bg-transparent hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
    //                     Voltar ao website da AMCO Intermediários de Crédito
    //                 </button>
    //             </div>
    //             <hr />
    //         </div>
    //     );
    // }

    // Fallback case (optional)
    return (
        <div className="space-y-4">
            <MdWarning className="w-[100px] h-[auto] text-orange-600 text-center ml-[auto] mr-[auto]" />
            <p className="font-bold text-xl text-orange-600 text-center">Informação Adicional</p>

            <p className="text-center md:pl-20 md:pr-20 sm:pl-4 sm:pr-4">
                O processo de <span className="font-semibold">{getCreditTypeDescriptionById(processData.creditType)}</span> que solicitou tem algumas especificidades.<br />
                Um dos nossos gestores de crédito irá entrar em contacto consigo para discutir os próximos passos e assegurar que todos os documentos necessários são enviados.
            </p>

            <div className="mt-20 flex space-between form-group col-12 px-0 d-block">
                <button type="button" onClick={onlySimulation} className="mr-2 w-full py-2 px-4 border border-dark rounded-md shadow-sm text-sm font-medium text-dark bg-transparent hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                    Voltar ao website da AMCO Intermediários de Crédito
                </button>
            </div>
            <hr />
        </div>
    );
};

StepThree.propTypes = {
    formData: PropTypes.object.isRequired,
    processData: PropTypes.object.isRequired,
    apiData: PropTypes.shape({
        creditType: PropTypes.number,
        amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        deadline: PropTypes.number
    }).isRequired,
    getCreditTypeDescriptionById: PropTypes.func.isRequired,
    onlySimulation: PropTypes.func.isRequired,
    preApproval: PropTypes.func.isRequired,
    addSecondHolder: PropTypes.func
};

export default StepThree;