import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useLocation } from 'react-router-dom';
import DocumentUpload from '../Documents/DocumentUpload';
import { MdOutlineSpeed, MdVisibility, MdVisibilityOff, MdOutlineDocumentScanner, MdPerson, MdPeopleAlt, MdClose } from "react-icons/md";
import apiService from '../../services/apiService';
import NavBar from '../Common/NavBar';
import CopyrightInfo from '../Common/CopyrightInfo';
import queryString from 'query-string';

import '../../docs.css';

const automaticDocuments = [
  "Mapa de Responsabilidades",
  "Comprovativo de Morada",
  "Último Modelo 3 do IRS"
];

const docDescriptions = {
  "Mapa de Responsabilidades": "O Mapa de Responsabilidades pode ser obtido diretamente através da Central de Responsabilidades de Crédito do Banco de Portugal.",
  "Comprovativo de Morada": "A sua Certidão de Domícilio Fiscal, retirada no Portal das Finanças, ou uma fatura da água, eletricidade ou telecomunicações, por exemplo. Deve ter uma data inferior a 3 meses.",
  "3 Últimos Recibos de Vencimento": "Uma cópia dos seus recibos de vencimento mais recentes. Pode enviar num só documento ou um de cada vez, a escolha é sua.",
  "Cartão de Cidadão": "Frente e verso do seu Cartão de Cidadão. Pode enviar um lado de cada vez.",
  "Último Modelo 3 do IRS": "Poderá encontrá-lo na sua área pessoal no Portal das Finanças. Deverá ser visível a primeira página completa do Modelo 3 do seu último IRS.",
  "Comprovativo do IBAN": "Pode pedir no Website/App do seu Banco ou no MULTIBANCO. O seu nome deve ser visível e a data do documento inferior a 3 meses.",
  "Informação Prévia RGPD": "Documento obtido automaticamente e disponível na primeira comunicação via email efetuada entre si e a AMCO Intermediários de Crédito, Lda."
};

const ProcessDocuments = () => {
  const auth = useAuth();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const urlToken = queryParams.token;
  const navigate = useNavigate();

  const [open, setOpen] = useState(localStorage.getItem('currentTab') || '1stHolder');
  const [leadToken, setLeadToken] = useState(localStorage.getItem('authToken'));
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [modalForm, setModalForm] = useState({ finances_password: '' });
  const [clientCount, setClientCount] = useState(0);
  const [shouldDisplayBlock, setShouldDisplayBlock] = useState(false);
  const [fileListFirstHolder, setFileListFirstHolder] = useState([]);
  const [fileListSecondHolder, setFileListSecondHolder] = useState([]);
  const [currentHolderNIF, setCurrentHolderNIF] = useState('');

  const togglePasswordVisibility = () => { setShowPassword(!showPassword); };

  const trigger = useRef(null);
  const modal = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modal.current && trigger.current && !modal.current.contains(event.target) && !trigger.current.contains(event.target)) {
        setModalOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [modalOpen]);

  useEffect(() => {
    if (!urlToken) {
      navigate('/login');
      return;
    }

    if (urlToken !== leadToken) {
      console.log('New urlToken detected. Updating localStorage.');
      localStorage.setItem('authToken', urlToken);
      setLeadToken(urlToken);
    }

    fetchInitialData();
  }, [leadToken, navigate, urlToken]);

  const fetchInitialData = async () => {
    try {
      const leadToken_stored = localStorage.getItem('authToken');
      const apiData = await apiService.fetchInitialData(leadToken_stored);
      const procProcess = apiData.proc_processes && apiData.proc_processes[0];
      const procProcessClients = procProcess.proc_process_clients;

      setClientCount(procProcessClients.length);
      updateDisplayBlock(procProcessClients);
    } catch (error) {
      console.error("Failed to fetch lead info", error);
      navigate('/error');
    }
  };

  const updateDisplayBlock = (procProcessClients) => {
    const holder = procProcessClients.find(c => c.idHolder === (open === '1stHolder' ? 1 : 2));

    if (holder) {
      setCurrentHolderNIF(holder.nif);
    }

    const display = holder && holder.finances === null;

    setShouldDisplayBlock(display);
  };

  useEffect(() => {
    localStorage.setItem('currentTab', open);
    if (clientCount > 0) {
      fetchInitialData();
    }
  }, [open]);

  const handleTabOpen = (tabCategory) => {
    setOpen(tabCategory);
  };

  const handleModalFormChange = (e) => {
    const { name, value } = e.target;
    setModalForm(prev => ({ ...prev, [name]: value }));
  };

  const handleModalFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const processId = localStorage.getItem('processId');
      const tabNumber = localStorage.getItem('currentTab') === '1stHolder' ? '1' : '2';
      const formData = {
        [tabNumber]: { finances: modalForm.finances_password }
      };

      await apiService.updateLeadData(processId, formData);
      const responseRobot = await apiService.getAutomaticDocs({
        TIN: currentHolderNIF,
        tinPassword: modalForm.finances_password,
        processId
      });

      console.log('Automatic documents response:', responseRobot);

      setModalOpen(false);
      setModalForm({ finances: '' });
    } catch (error) {
      console.error('Error submitting modal form:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileUploadFirstHolder = async (name, file, docId, holder, type, idProcess) => {
    const updatedFileListFirstHolder = fileListFirstHolder.map(doc => {
      if (doc.name === name) {
        return { ...doc, file, checked: true };
      }
      return doc;
    });

    setFileListFirstHolder(updatedFileListFirstHolder);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('docId', docId);
    formData.append('holder', holder);
    formData.append('type', type);
    formData.append('idProcess', idProcess);

    const processId = localStorage.getItem('processId');
    try {
      const response = await apiService.uploadDocument(processId, formData);
      console.log('File uploaded successfully', response);
    } catch (error) {
      console.error('Error uploading file', error);
    }
  };

  const handleFileUploadSecondHolder = async (name, file, docId, holder, type, idProcess) => {
    const updatedFileListSecondHolder = fileListSecondHolder.map(doc => {
      if (doc.name === name) {
        return { ...doc, file, checked: true };
      }
      return doc;
    });
    setFileListSecondHolder(updatedFileListSecondHolder);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('docId', docId);
    formData.append('holder', holder);
    formData.append('type', type);
    formData.append('idProcess', idProcess);

    const processId = localStorage.getItem('processId');
    try {
      const response = await apiService.uploadDocument(processId, formData);
      console.log('File uploaded successfully', response);
    } catch (error) {
      console.error('Error uploading file', error);
    }
  };

  const handleDeleteFile = async (docId, holder, idProcess) => {
    const processId = localStorage.getItem('processId');
    try {
      const response = await apiService.deleteDocument({ id: docId, id_process: parseInt(processId, 10) });
      console.log('File deleted successfully', response);

      if (holder === 1) {
        const updatedFileListFirstHolder = fileListFirstHolder.map(doc => {
          if (doc.id === docId) {
            return { ...doc, file: null, thumbnail: null, checked: false };
          }
          return doc;
        });
        setFileListFirstHolder(updatedFileListFirstHolder);
      } else if (holder === 2) {
        const updatedFileListSecondHolder = fileListSecondHolder.map(doc => {
          if (doc.id === docId) {
            return { ...doc, file: null, thumbnail: null, checked: false };
          }
          return doc;
        });
        setFileListSecondHolder(updatedFileListSecondHolder);
      }

      window.location.reload();
    } catch (error) {
      console.error('Error deleting file', error);
    }
  };

  const totalDocumentsFirstHolder = fileListFirstHolder.length;
  const uploadedDocumentsFirstHolder = fileListFirstHolder.filter(doc => doc.checked).length;

  const totalDocumentsSecondHolder = fileListSecondHolder.length;
  const uploadedDocumentsSecondHolder = fileListSecondHolder.filter(doc => doc.checked).length;

  const [currentTab, setCurrentTab] = useState(localStorage.getItem('currentTab') || '1stHolder');

  useEffect(() => {
    const handleTabChange = () => {
      setCurrentTab(localStorage.getItem('currentTab'));
    };

    window.addEventListener('storage', handleTabChange);

    return () => {
      window.removeEventListener('storage', handleTabChange);
    };
  }, []);

  const fetchDocuments = async () => {
    try {
      const processId = localStorage.getItem('processId');
      const currentTab = localStorage.getItem('currentTab');
      const holderNumber = currentTab === '1stHolder' ? 1 : currentTab === '2ndHolder' ? 2 : null;
      const documents = await apiService.fetchDocumentsByProcess(processId);
      const docsFirstHolder = [];
      const docsSecondHolder = [];

      documents.forEach(doc => {
        const isAutomatic = automaticDocuments.includes(doc.type);
        const isFinanceValid = doc.holder === holderNumber && !shouldDisplayBlock;

        const documentDetail = {
          id: doc.id,
          name: doc.type,
          description: docDescriptions[doc.type] || "Sem descrição disponível.",
          file: isAutomatic && !isFinanceValid ? "Documento Obtido Automaticamente" : doc.file,
          checked: isAutomatic && !isFinanceValid,
          holder: doc.holder,
          disabled: isAutomatic && !isFinanceValid,
        };

        if (doc.holder === 1) {
          docsFirstHolder.push(documentDetail);
        } else if (doc.holder === 2) {
          docsSecondHolder.push(documentDetail);
        }
      });

      setFileListFirstHolder(docsFirstHolder);
      setFileListSecondHolder(docsSecondHolder);
    } catch (error) {
      console.error('Failed to fetch documents:', error);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  return (
    <div>
      <div className={`z-20 fixed left-0 top-0 flex h-full min-h-screen w-full items-center justify-center bg-dark/90 px-4 py-5 ${modalOpen ? "block" : "hidden"}`}>
        <div
          ref={modal}
          className="w-full max-w-[570px] rounded-[20px] bg-white px-8 py-12 relative"
        >
          <button
            type="button"
            onClick={() => setModalOpen(false)}
            className="absolute z-10 bg-white rounded-md text-gray-400 hover:text-[#b78b1e] focus:outline-none right-4 top-4"
          >
            <span className="sr-only">Close</span>
            <MdClose className="h-6 w-6" aria-hidden="true" />
          </button>

          <div className="text-center">
            <h2 className="w-full text-center justify-center pb-8 text-xl font-bold text-dark flex">
              <MdOutlineSpeed size={24} className="mr-2" />
              Acelerar Processo
            </h2>
          </div>

          <p className="text-base md:text-base sm:text-sm block pb-8">
            Por favor indique-nos a sua password do Portal das Finanças de forma a acelerar o seu processo e obter a documentação necessária de forma automática.
          </p>

          <form id="financeForm" onSubmit={handleModalFormSubmit}>
            <div className="form-group col-span-2 relative">
              <input
                name="finances_password"
                id="finances"
                type={showPassword ? 'text' : 'password'}
                placeholder='Introduza a sua password do Portal das Finanças'
                className='text-sm w-full bg-white rounded-md border border-stroke dark:border-dark-3 py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                value={modalForm.finances_password}
                onChange={handleModalFormChange}
                required
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-600"
              >
                {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
              </button>
            </div>
          </form>

          <div className="-mx-3 flex flex-wrap">
            <div className="w-full px-3">
              <button
                type="submit"
                form="financeForm"
                disabled={loading}
                className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-bold text-white amco-blue mt-2 mb-8">
                {loading ? (
                  <div className="spinner" />
                ) : (
                  'Enviar dados de acesso'
                )}
              </button>
              <span className="text-xs block">
                * Ao submeter os seus dados de acesso ao Portal das Finanças declara que leu e compreendeu a <a href="#https://amco.pt/politica-de-privacidade/" className="font-semibold">Política de Privacidade</a> com a qual concorda, para efeito do tratamento dos seus dados.
              </span>
            </div>
          </div>
        </div>
      </div>

      <NavBar />

      <div className="flex justify-center bg-amco-gold container-noNav">
        <div className="w-full soft-bg roundContainer p-10 ml-5 mr-5 max-w-screen-xl">
          <h2 className="pb-8 text-xl font-bold flex">
            <MdOutlineDocumentScanner className="mr-2 mt-1" />
            Os Meus Documentos
          </h2>

          {shouldDisplayBlock && (
            <div className="rounded-lg bg-[#DBF3FE] p-6 mt-4 grid md:grid-cols-12 sm:grid-cols-1 gap-4 mb-7">
              <div className="col-span-4">
                <button ref={trigger} onClick={() => setModalOpen(true)} type="button" className="justify-center inline py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-bold text-white amco-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:amco-blue/75 flex w-full">
                  <MdOutlineSpeed size={20} className="mr-2" />
                  Acelerar Processo
                </button>
              </div>

              <div className="col-span-8">
                <p className="flex items-center text-sm text-12 text-dark font-bold">
                  Existe uma maior probabilidade do seu crédito ser aprovado incluindo um segundo titular. Pretende incluir um segundo titular no seu processo de crédito?
                </p>
              </div>
            </div>
          )}

          <div className="grid grid-cols-12">
            <div className="col-span-12">
              {currentTab === '1stHolder' && (
                <p className="font-bold text-sm mb-6">Documentos Exigidos ({uploadedDocumentsFirstHolder}/{totalDocumentsFirstHolder})</p>
              )}

              {currentTab === '2ndHolder' && (
                <p className="font-bold text-sm mb-6">Documentos Exigidos ({uploadedDocumentsSecondHolder}/{totalDocumentsSecondHolder})</p>
              )}
              <div className="mb-14 w-full">
                <div className="z-0 relative inline-flex cursor-pointer select-none items-center justify-center">

                  {clientCount >= 1 && (
                    <a onClick={() => handleTabOpen("1stHolder")}
                      className={`flex items-center space-x-[6px] py-2 px-[18px] text-sm font-medium ${open === "1stHolder"
                        ? "border-b-[3px] border-[#B78B1E]"
                        : "text-[#9ca3af]"
                        }`}
                    >
                      <MdPerson className="mr-2" />
                      1º Titular
                    </a>
                  )}

                  {clientCount >= 2 && (
                    <a onClick={() => handleTabOpen("2ndHolder")}
                      className={`flex items-center space-x-[6px] py-2 px-[18px] text-sm font-medium ${open === "2ndHolder"
                        ? "border-b-[3px] border-[#B78B1E]"
                        : "text-[#9ca3af]"
                        }`}
                    >
                      <MdPeopleAlt className="mr-2" />
                      2º Titular
                    </a>
                  )}
                </div>

                <TabContent
                  details={
                    <div>
                      {fileListFirstHolder.map((doc) => (
                        <DocumentUpload
                          key={doc.id}
                          idProcess={doc.id_process}
                          doc={doc}
                          type={doc.type}
                          holder='1'
                          onFileUpload={handleFileUploadFirstHolder}
                          onDeleteFile={handleDeleteFile}
                        />
                      ))}
                    </div>
                  }
                  tabCategory="1stHolder"
                  open={open}
                />

                <TabContent
                  details={
                    <div>
                      {fileListSecondHolder.map((doc) => (
                        <DocumentUpload
                          key={doc.id}
                          idProcess={doc.id_process}
                          doc={doc}
                          type={doc.type}
                          holder='2'
                          onFileUpload={handleFileUploadSecondHolder}
                          onDeleteFile={handleDeleteFile}
                        />
                      ))}
                    </div>
                  }
                  tabCategory="2ndHolder"
                  open={open}
                />
              </div>
            </div>
          </div>

          <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-0 mt-12">
            <CopyrightInfo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessDocuments;

const TabContent = ({ open, tabCategory, details }) => {
  return (
    <div>
      <div className={`mt-4 text-base leading-relaxed text-body-color dark:text-dark-6 ${open === tabCategory ? "block" : "hidden"} `}>
        {details}
      </div>
    </div>
  );
};
