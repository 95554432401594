import manager from "../../assets/amco-manager.png";
import {
    MdOutlineWhatsapp,
    MdEmail,
    MdLocalPhone
} from "react-icons/md";

const ContactBanner = () => {
    return (
        <div className="manager-container">
            <div className="flex md:h-[100px] sm:h-auto bg-white rounded-lg shadow-md">
                <img
                    className="w-auto absolute"
                    src={manager}
                    alt="AMCO | Intermediários de Crédito"
                />
                <div className="pt-[18px] grid md:grid-cols-7 sm:grid-cols-1 align-middle content-manager">
                    <p className="text-gray-700 font-medium md:col-span-3 sm:col-span-1">
                        <p className="text-[#111827] font-semibold md:text-md sm:text-sm ">
                            Tem alguma dúvida sobre o seu processo?
                        </p>
                        <p className="text-xs">Caso precise de esclarecer alguma dúvida ou tenha alguma informação útil relativa ao seu processo de financiamento, entre em contacto connosco.</p>
                    </p>

                    <div className="flex align-middle col-span-4 md:px-10 pt-[20px] mobile-spacing-2 justify-between">
                        <a href="tel:+351912608364" className="hover:underline flex text-[#111827]">
                            <MdLocalPhone className="w-6 h-6 px-[4px] mr-1 rounded-full bg-[#428a1f] text-[#fff]" />
                            +351 912 608 364
                        </a>

                        <a href="mailto:geral@amco.pt" className="hover:underline flex text-[#111827]">
                            <MdEmail className="w-6 h-6 px-[4px] mr-1 rounded-full bg-[#E60023] text-[#fff]" />
                            geral@amco.pt
                        </a>

                    </div>

                </div>
            </div>
        </div>
    );
};

export default ContactBanner;