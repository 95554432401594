import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext'; // Import useAuth hook
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';

import { MdInfoOutline, MdLogin, MdLockClock, MdOutlineRefresh } from "react-icons/md";
import PrivateRoute from '../../components/PrivateRoute';
import NavBar from '../Common/NavBar';
import BancoPortugalInfo from '../Common/BancoPortugalInfo';
import CopyrightInfo from '../Common/CopyrightInfo';

const LoginScreen = () => {
    const [input, setInput] = useState('');
    const [otp, setOtp] = useState('');
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [error, setError] = useState("");
    const [countdown, setCountdown] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const { requestOtp, verifyOtp } = useAuth();
    const navigate = useNavigate();
    const { user } = useAuth();

    useEffect(() => {
        if (user) {
            window.dataLayer.push({ event: 'user_already_logged_in' }); // GTM Event: User is already logged in
            navigate('/dashboard');
        } else {
            navigate('/login');
        }

        if (countdown > 0) {
            const interval = setInterval(() => {
                setCountdown(countdown - 1);
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [showOtpInput, countdown, user, navigate]);

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await requestOtp(input);
            setShowOtpInput(true);
            setCountdown(180);

            // GTM Event: OTP Request Sent
            window.dataLayer.push({
                event: 'otp_request_sent',
                category: 'Login',
                action: 'Request OTP',
                label: 'OTP requested'
            });

        } catch (error) {
            console.error('Error requesting OTP:', error);
            setError('Verifique por favor a validade dos dados introduzidos.');

            // GTM Event: OTP Request Failed
            window.dataLayer.push({
                event: 'otp_request_failed',
                category: 'Login',
                action: 'Request OTP Failed',
                label: 'Invalid email or phone'
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleOtpSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const accessToken = localStorage.getItem('accessToken');
        const inputFromStorage = localStorage.getItem('input');

        try {
            const response = await verifyOtp(inputFromStorage, otp, accessToken);

            if (response && response.valid) {
                localStorage.setItem('sessionToken', response.sessionToken);
                navigate('/dashboard');
                window.location.href = '/dashboard';

                // GTM Event: OTP Verification Success
                window.dataLayer.push({
                    event: 'otp_verification_success',
                    category: 'Login',
                    action: 'OTP Verified',
                    label: 'User logged in successfully'
                });
            } else {
                setError(response.message || 'Invalid OTP. Please try again.');

                // GTM Event: OTP Verification Failed
                window.dataLayer.push({
                    event: 'otp_verification_failed',
                    category: 'Login',
                    action: 'OTP Verification Failed',
                    label: 'Invalid OTP entered'
                });
            }
        } catch (error) {
            console.error('Error submitting OTP:', error);
            setError('Invalid OTP or an error occurred during OTP verification.');

            // GTM Event: OTP Verification Error
            window.dataLayer.push({
                event: 'otp_verification_error',
                category: 'Login',
                action: 'OTP Verification Error',
                label: 'Error during OTP verification'
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleResendOtp = async () => {
        try {
            await requestOtp(input);
            setCountdown(180);

            // GTM Event: OTP Resent
            window.dataLayer.push({
                event: 'otp_resent',
                category: 'Login',
                action: 'Resend OTP',
                label: 'OTP resent to user'
            });

        } catch (error) {
            console.error('Error resending OTP:', error);
            setError('An error occurred while attempting to resend the OTP.');

            // GTM Event: OTP Resend Failed
            window.dataLayer.push({
                event: 'otp_resend_failed',
                category: 'Login',
                action: 'Resend OTP Failed',
                label: 'Error while resending OTP'
            });
        }
    };

    return (
        <div>
            <NavBar />
            <div className="flex justify-center bg-amco-gold container-noNav">
                <div className="w-full soft-bg roundContainer p-10 ml-5 mr-5 max-w-screen-xl justify-center">
                    <h2 className="text-lg md:text-lg sm:text-base">
                        <span className="font-bold">Bem-vindo ao Estado do Processo</span><br />
                        <span className="text-base md:text-base sm:text-12 block mt-6">Introduza o <span className="font-medium">endereço de email</span> ou <span className="font-medium">número de telefone</span> que indicou no seu pedido de crédito para verificar o estado do seu processo ou avançar com a pré-aprovação.</span>
                    </h2>

                    {showOtpInput ? (
                        <form className="mt-10 otp-form" onSubmit={handleOtpSubmit}>
                            <label htmlFor="otp-code" className='mb-4 block text-base md:text-base sm:text-sm font-medium text-dark'>Introduza o código recebido por SMS</label>
                            <OtpInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                inputStyle={{
                                    border: "1px solid #25232C",
                                    margin: "0 4px",
                                    borderRadius: "8px",
                                    width: "60px",
                                    height: "60px",
                                    fontSize: "32px",
                                    color: "#000",
                                    fontWeight: "700",
                                    caretColor: "#B78B1E"
                                }}
                                className="otp-input"
                                renderSeparator={<span>-</span>}
                                renderInput={(props) => <input {...props} />}
                            />

                            <div className="mt-10 grid grid-cols-1">
                                <button type="submit" className="mb-8 amco-blue hover:amco-blue/75 text-white font-bold py-2 px-4 rounded">
                                    {isLoading ? (
                                        <div className="spinner" />
                                    ) : (
                                        'Validar Conta'
                                    )}
                                </button>

                                <a href="#" onClick={handleResendOtp} className="flex align-center place-content-center">
                                    <span className="text-xs text-center text-amco-blue">Não recebeu o código? Reenviar por SMS</span>
                                    <MdOutlineRefresh />
                                </a>

                            </div>
                        </form>
                    ) : (
                        <form className="mt-10" onSubmit={handleEmailSubmit}>
                            <label htmlFor="inputUserData" className='mb-4 block text-base md:text-base sm:text-sm font-medium font-medium text-dark'>Endereço de Email ou Telefone:</label>
                            <input
                                required
                                name="inputUserData" id="inputUserData"
                                type="text"
                                value={input}
                                placeholder='Introduza o seu endereço de email ou telefone'
                                onChange={(e) => setInput(e.target.value)}
                                className='mb-4 text-sm w-full bg-transparent rounded-md border border-stroke dark:border-dark-3 py-[10px] px-5 text-dark outline-none transition focus:amco-blue active:amco-blue disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                            />

                            <div className="grid grid-cols-1">
                                <button type="submit" className="mb-10 amco-blue hover:amco-blue/75 text-white font-bold py-2 px-4 rounded">
                                    {isLoading ? (
                                        <div className="spinner" />
                                    ) : (
                                        "Login Simplificado"
                                    )}
                                </button>

                                <div className="rounded-md bg-cyan-light-2/30 p-4">
                                    <p className="flex items-center text-sm font-medium text-dark text-xs">
                                        <span className="pr-3">
                                            <MdInfoOutline />
                                        </span>
                                        Aceda de forma rápida e segura à sua conta sem precisar de uma password.
                                    </p>
                                </div>
                            </div>
                        </form>
                    )}

                    {error && <p className="text-red-500">{error}</p>}

                    <div className="grid md:grid-cols-2 gap-0 mt-12 sm:grid-cols-1">
                        <CopyrightInfo />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginScreen;
