import React, { useEffect, useState } from 'react';
import { useParams, Navigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import LeadForm from './Lead/LeadForm';
import NoAccessPage from './Auth/NoAccessPage';
import axios from 'axios';

const LeadTokenValidator = () => {
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const leadToken = queryParams.token;

    const authToken = ")d45UnzdL&&(Z#Ka2RzDYG#retRIyfn)ySjFUqFm$e|)ArMA$gVT0fpc10@ypCXA";

    const config = {
        headers: { Authorization: `Bearer ${authToken}` }
    };

    const [isValidToken, setIsValidToken] = useState(null);

    useEffect(() => {
        const validateToken = async () => {
            try {
                const response = await axios.get(`https://api.amco.pt/api/leads/token?leadToken=${leadToken}`, config);
                // console.log(response.data);
                setIsValidToken(true);
            } catch (error) {
                setIsValidToken(false);
            }
        };

        if (leadToken) {
            validateToken();
        } else {
            setIsValidToken(false);
        }
    }, [leadToken]);

    if (isValidToken === null) {
        return <div>A obter dados da lead...</div>;
    }

    return isValidToken ? <LeadForm token={leadToken} /> : <NoAccessPage />;
};

export default LeadTokenValidator;
