import React from 'react';
import { MdOutlineArrowBack } from "react-icons/md";

const LeadFormButtons = ({
    currentStep,
    selectedOption,
    isStepValid,
    loading,
    nextStep,
    prevStep,
    simulateProcess,
    formErrors,
}) => {
    return (
        <div className="justify-between">
            {(currentStep === 1 && selectedOption !== 'no') && (
                <button
                    type="button"
                    onClick={isStepValid ? nextStep : undefined}
                    disabled={!isStepValid || !selectedOption || Object.keys(formErrors).length > 0}
                    className={`w-full py-5 px-4 border border-transparent rounded-md shadow-sm text-sm font-bold text-white amco-blue ${selectedOption ? "hover:amco-blue/75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-amco-gold-simple" : "opacity-50 cursor-not-allowed"}`}
                >
                    Guardar e Avançar
                </button>
            )}

            {((selectedOption === 'no' && currentStep === 1) || (selectedOption === 'yes' && currentStep === 2) || currentStep === 2) && (
                <button
                    type="button"
                    onClick={simulateProcess}
                    className="w-full py-5 px-4 border border-transparent rounded-md shadow-sm text-sm font-bold text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                    {loading ? (
                        <div className="spinner" />
                    ) : (
                        'Saber o valor da minha prestação'
                    )}
                </button>
            )}

            {selectedOption === 'yes' && currentStep > 1 && currentStep < 3 && (
                <button type="button" onClick={prevStep} className="mt-[10px] w-full flex py-2 px-4 text-sm font-medium text-dark justify-center ">
                    <MdOutlineArrowBack className="mt-[4px] mr-2" />
                    Voltar
                </button>
            )}
        </div>
    );
};

export default LeadFormButtons;