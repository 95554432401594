import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { RadioGroup } from '@headlessui/react';
import { MdOutlineLock, MdOutlinePersonAddAlt, MdOutlineExpandMore } from "react-icons/md";
import CheckIcon from '../Common/CheckIcon';

const StepOne = ({ formData, handleInputChange, handleDateChange, handleKeyDown, preventManualInput, formErrors, allDataOptions, setSelectedOption, selectedOption }) => {
    useEffect(() => {
        const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format

        // Set default values if housingType is not 1 or 3
        if (formData.housingType !== 1 && formData.housingType !== '1' && formData.housingType !== 3 && formData.housingType !== '3') {
            if (!formData.rentDate) {
                handleInputChange({ target: { name: 'rentDate', value: currentDate } });
            }
            if (!formData.rentHome) {
                handleInputChange({ target: { name: 'rentHome', value: '0' } });
            }
        }
    }, [formData.housingType, formData.rentDate, formData.rentHome, handleInputChange]);

    return (
        <div>
            <h2 className="text-xl font-semibold text-gray-700">Dados Pessoais</h2>
            <hr className="mt-2" />
            <div className="grid md:md:grid-cols-3 sm:grid-cols-1 sm:grid-cols-1 gap-4 mt-4">
                <div className="form-group col-span-2">
                    <label htmlFor="name" className='inline flex mb-[10px] block text-sm font-medium text-dark'>
                        Nome Completo
                        <MdOutlineLock className="mt-[2px] ml-[4px]" />
                    </label>
                    <input
                        disabled
                        name="name" id="name"
                        onChange={handleInputChange}
                        type='text'
                        placeholder='Introduza o seu nome completo'
                        className='text-sm w-full bg-white rounded-md border border-stroke dark:border-dark-3 py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2 disabled:border-dark disabled:text-dark-6'
                        value={formData.name}
                    />
                    {formErrors['name'] && <p className="pt-1 text-xs text-red-500">{formErrors['name']}</p>}
                </div>

                <div className="form-group">
                    <label htmlFor="birthDate" className='mb-[10px] block text-sm font-medium text-dark'>
                        Data De Nascimento
                    </label>
                    <input
                        required
                        name="birthDate"
                        id="birthDate"
                        onChange={handleDateChange}
                        type="date"
                        min="1900-01-01"
                        max="2023-12-31"
                        onKeyDown={preventManualInput} // Disable typing by preventing keydown events
                        onPaste={preventManualInput}
                        value={formData.birthDate ? formData.birthDate.split('T')[0] : ''}
                        className='text-sm w-full bg-white rounded-md border border-stroke dark:border-dark-3 py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                    />
                    {formErrors['birthDate'] && <p className="pt-1 text-xs text-red-500">{formErrors['birthDate']}</p>}
                </div>
            </div>

            <div className="grid md:grid-cols-3 sm:grid-cols-1 gap-4 mt-4">
                <div className="form-group">
                    <div className="anchor" id="gender"></div>
                    <label className='mb-[10px] block text-sm font-medium text-dark' htmlFor="gender">
                        Género
                    </label>
                    <div className='relative z-20'>
                        <select
                            required
                            name="gender"
                            id="gender"
                            className='text-sm relative z-20 w-full rounded-lg border border-stroke dark:border-dark-3 bg-white py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2'
                            value={formData.gender}
                            onChange={handleInputChange}
                        >
                            <option value="" disabled selected>Escolha o seu género</option>
                            {allDataOptions.gender && allDataOptions.gender.map((genderOption) => (
                                <option key={genderOption.id} value={genderOption.id} className='dark:bg-dark-2'>
                                    {genderOption.gender}
                                </option>
                            ))}
                        </select>
                        <span className='absolute right-4 top-1/2 z-10 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2 border-body-color'></span>
                    </div>
                    {formErrors['gender'] && <p className="pt-1 text-xs text-red-500">{formErrors['gender']}</p>}
                </div>

                <div className="form-group">
                    <div className="anchor" id="maritalAnchor"></div>
                    <label className='mb-[10px] block text-sm font-medium text-dark' htmlFor="civilStatus">
                        Estado Civil
                    </label>
                    <div className='relative z-20'>
                        <select
                            required
                            name="civilStatus"
                            id="civilStatus"
                            className='text-sm relative z-20 w-full   rounded-lg border border-stroke dark:border-dark-3 bg-white py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2'
                            value={formData.civilStatus}
                            onChange={handleInputChange}
                        >
                            <option value="" disabled selected>Qual o seu estado civil?</option>
                            {allDataOptions.maritalStatus && allDataOptions.maritalStatus.map((maritalOption) => (
                                <option key={maritalOption.id} value={maritalOption.id} className='dark:bg-dark-2'>
                                    {maritalOption.name}
                                </option>
                            ))}
                        </select>
                        <span className='absolute right-4 top-1/2 z-10 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2 border-body-color text-dark'>
                            <MdOutlineExpandMore />
                        </span>
                    </div>
                    {formErrors['civilStatus'] && <p className="pt-1 text-xs text-red-500">{formErrors['civilStatus']}</p>}
                </div>

                <div className="form-group">
                    <label htmlFor="nif" className='mb-[10px] block text-sm font-medium text-dark'>
                        NIF
                    </label>
                    <input
                        required
                        name="nif" id="nif"
                        type='text'
                        value={formData.nif}
                        onChange={handleInputChange}
                        placeholder='O seu número contribuinte'
                        className='text-sm w-full bg-white rounded-md border border-stroke dark:border-dark-3 py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                    />
                    {formErrors['nif'] && <p className="pt-1 text-xs text-red-500">{formErrors['nif']}</p>}
                </div>
            </div>

            <div className="grid md:grid-cols-3 sm:grid-cols-1 gap-4 mt-4">
                <div className="form-group">
                    <label htmlFor="phone" className='inline flex mb-[10px] block text-sm font-medium text-dark'>
                        Telemóvel
                        <MdOutlineLock className="mt-[2px] ml-[4px]" />
                    </label>
                    <input
                        disabled
                        name="phone" id="phone"
                        onChange={handleInputChange}
                        type='text'
                        value={formData.phone}
                        placeholder='Introduza o seu número de telemóvel'
                        className='text-sm w-full bg-white rounded-md border border-stroke dark:border-dark-3 py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2 disabled:border-dark disabled:text-dark-6'
                    />
                    {formErrors['phone'] && <p className="pt-1 text-xs text-red-500">{formErrors['phone']}</p>}
                </div>
                <div className="form-group col-span-2">
                    <label htmlFor="email" className='inline flex mb-[10px] block text-sm font-medium text-dark'>
                        Endereço de Email
                        <MdOutlineLock className="mt-[2px] ml-[4px]" />
                    </label>
                    <input
                        disabled
                        name="email" id="email"
                        onChange={handleInputChange}
                        value={formData.email}
                        type='email'
                        placeholder='Introduza o seu endereço de email'
                        className='text-sm w-full bg-white rounded-md border border-stroke dark:border-dark-3 py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2 disabled:border-dark disabled:text-dark-6'
                    />
                    {formErrors['email'] && <p className="pt-1 text-xs text-red-500">{formErrors['email']}</p>}
                </div>
            </div>

            <div className="grid md:grid-cols-3 sm:grid-cols-1 gap-4 mt-4">
                <div className="form-group">
                    <div className="anchor" id="country"></div>
                    <label className='mb-[10px] block text-sm font-medium text-dark' htmlFor="country">
                        Nacionalidade
                    </label>
                    <div className='relative z-20'>
                        <select
                            required
                            name="country"
                            id="country"
                            className='text-sm relative z-20 w-full   rounded-lg border border-stroke dark:border-dark-3 bg-white py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2'
                            value={formData.country}
                            onChange={handleInputChange}
                        >
                            <option value="" disabled selected>Escolha a sua nacionalidade</option>
                            {Array.isArray(allDataOptions.country) && allDataOptions.country.length > 0 ? (
                                allDataOptions.country.map((countryOption) => (
                                    <option key={countryOption.id} value={countryOption.id} className="dark:bg-dark-2">
                                        {countryOption.country}
                                    </option>
                                ))
                            ) : (
                                <option disabled>A carregar...</option>
                            )}
                        </select>
                        <span className='absolute right-4 top-1/2 z-10 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2 border-body-color'></span>
                    </div>
                    {formErrors['country'] && <p className="pt-1 text-xs text-red-500">{formErrors['country']}</p>}
                </div>
            </div>

            <h2 className="text-xl font-semibold text-gray-700 mt-10">Emprego</h2>
            <hr className="mt-2" />
            <div className="grid md:grid-cols-3 sm:grid-cols-1 gap-4 mt-4">
                <div className="form-group">
                    <div className="anchor" id="employerTypeAnchor"></div>
                    <label className='mb-[10px] block text-sm font-medium text-dark' htmlFor="employerType">
                        Entidade Empregadora
                    </label>
                    <div className='relative z-20'>
                        <select
                            required
                            name="employerType"
                            id="employerType"
                            className='text-sm relative z-20 w-full   rounded-lg border border-stroke dark:border-dark-3 bg-white py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2'
                            value={formData.employerType}
                            onChange={handleInputChange}
                        >
                            <option value="" disabled selected>Escolha o tipo de empregador</option>
                            {allDataOptions.employerType && allDataOptions.employerType.map((employerOption) => (
                                <option key={employerOption.id} value={employerOption.id} className='dark:bg-dark-2'>
                                    {employerOption.name}
                                </option>
                            ))}
                        </select>
                        <span className='absolute right-4 top-1/2 z-10 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2 border-body-color'></span>
                    </div>
                    {formErrors['employerType'] && <p className="pt-1 text-xs text-red-500">{formErrors['employerType']}</p>}
                </div>

                <div className="form-group">
                    <div className="anchor" id="contractTypeAnchor"></div>
                    <label className='mb-[10px] block text-sm font-medium text-dark' htmlFor="contractType">
                        Situação Profissional
                    </label>
                    <div className='relative z-20'>
                        <select
                            required
                            name="contractType"
                            id="contractType"
                            className='text-sm relative z-20 w-full   rounded-lg border border-stroke dark:border-dark-3 bg-white py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2'
                            value={formData.contractType}
                            onChange={handleInputChange}
                        >
                            <option value="" disabled selected>Qual a sua situação profissional?</option>
                            {allDataOptions.contractType && allDataOptions.contractType.map((contractOption) => (
                                <option key={contractOption.id} value={contractOption.id} className='dark:bg-dark-2'>
                                    {contractOption.name}
                                </option>
                            ))}
                        </select>
                        <span className='absolute right-4 top-1/2 z-10 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2 border-body-color'></span>
                    </div>
                    {formErrors['contractType'] && <p className="pt-1 text-xs text-red-500">{formErrors['contractType']}</p>}
                </div>

                <div className="form-group">
                    <label htmlFor="sinceCompany" className='mb-[10px] block text-sm font-medium text-dark'>
                        Desde que data (mês/ano)
                    </label>
                    <input
                        required
                        name="sinceCompany" id="sinceCompany"
                        type="date"
                        min="1900-01-01"
                        max="2023-12-31"
                        value={formData.sinceCompany ? formData.sinceCompany.split('T')[0] : ''}
                        onChange={handleDateChange}
                        onKeyDown={preventManualInput} // Disable typing by preventing keydown events
                        onPaste={preventManualInput}
                        className='text-sm w-full bg-white rounded-md border border-stroke dark:border-dark-3 py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                    />

                    {formErrors['sinceCompany'] && <p className="pt-1 text-xs text-red-500">{formErrors['sinceCompany']}</p>}
                </div>

                <div className="form-group">
                    <label htmlFor="salary" className='mb-[10px] block text-sm font-medium text-dark'>
                        Vencimento Mensal
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span className="text-gray-500 sm:text-sm">€ </span>
                        </div>
                        <input
                            required
                            type="text"
                            name="salary"
                            id="salary"
                            onChange={handleInputChange}
                            className="text-sm pl-7 w-full bg-white rounded-md border border-stroke dark:border-dark-3 py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2"
                            placeholder="0.00"
                            aria-describedby="salary"
                            value={formData.salary}
                        />
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <span className="text-gray-500 sm:text-sm" id="salary">
                                EUR
                            </span>
                        </div>
                    </div>
                    {formErrors['salary'] && <p className="pt-1 text-xs text-red-500">{formErrors['salary']}</p>}
                </div>
            </div>



            <h2 className="text-xl font-semibold text-gray-700 mt-10">Habitação</h2>
            <hr className="mt-2" />
            <div className="grid md:grid-cols-3 sm:grid-cols-1 gap-4 mt-4">
                <div className="form-group">
                    <div className="anchor" id="housingTypeAnchor"></div>
                    <label className='mb-[10px] block text-sm font-medium text-dark' htmlFor="housingType">
                        Tipo de Habitação
                    </label>
                    <div className='relative z-20'>
                        <select
                            name="housingType"
                            id="housingType"
                            className='text-sm relative z-20 w-full   rounded-lg border border-stroke dark:border-dark-3 bg-white py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2'
                            value={formData.housingType}
                            onChange={handleInputChange}
                        >
                            <option value="" disabled selected>Escolha o tipo de habitação</option>
                            {allDataOptions.housingType && allDataOptions.housingType.map((housingOption) => (
                                <option key={housingOption.id} value={housingOption.id} className='dark:bg-dark-2'>
                                    {housingOption.housing_type}
                                </option>
                            ))}
                        </select>
                        <span className='absolute right-4 top-1/2 z-10 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2 border-body-color'></span>
                    </div>
                    {formErrors['housingType'] && <p className="pt-1 text-xs text-red-500">{formErrors['housingType']}</p>}
                </div>

                {formData.housingType && (formData.housingType === 1 || formData.housingType === '1' || formData.housingType === 3 || formData.housingType === '3') && (
                    <>
                        <div className="form-group">
                            <label htmlFor="rentDate" className='mb-[10px] block text-sm font-medium text-dark'>
                                Desde que data (mês/ano)
                            </label>
                            <input
                                name="rentDate" id="rentDate"
                                type="date"
                                min="1900-01-01"
                                max="2023-12-31"
                                onChange={handleDateChange}
                                onKeyDown={preventManualInput} // Disable typing by preventing keydown events
                                onPaste={preventManualInput}
                                value={formData.rentDate ? formData.rentDate.split('T')[0] : ''}
                                className='text-sm w-full bg-white rounded-md border border-stroke dark:border-dark-3 py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                            />

                            {formErrors['rentDate'] && <p className="pt-1 text-xs text-red-500">{formErrors['rentDate']}</p>}
                        </div>

                        <div className="form-group">
                            <label htmlFor="rentHome" className='mb-[10px] block text-sm font-medium text-dark'>
                                Prestação/Renda
                            </label>
                            <div className="relative mt-2 rounded-md shadow-sm">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                    <span className="text-gray-500 sm:text-sm">€ </span>
                                </div>
                                <input
                                    type="text"
                                    name="rentHome"
                                    id="rentHome"
                                    onChange={handleInputChange}
                                    value={formData.rentHome || ''}
                                    className="text-sm pl-6 w-full bg-white rounded-md border border-stroke dark:border-dark-3 py-[10px] px-5 text-dark outline-none transition focus:bg-amco-gold-simple active:bg-amco-gold-simple disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2"
                                    placeholder="0.00"
                                    aria-describedby="rentHome"
                                />
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                    <span className="text-gray-500 sm:text-sm" id="rentHome">
                                        EUR
                                    </span>
                                </div>
                            </div>
                        </div>
                    </>
                )}

            </div>

            <h2 className="text-xl font-semibold text-gray-700 mt-10">Segundo Titular</h2>

            <div className="rounded-lg bg-[#DBF3FE] p-6 mt-4">
                <p className="flex items-center text-sm  text-dark font-bold">
                    <span className="pr-4">
                        <MdOutlinePersonAddAlt size={24} />
                    </span>
                    Existe uma maior probabilidade do seu crédito ser aprovado incluindo um segundo titular. Pretende incluir um segundo titular no seu processo de crédito?
                </p>
            </div>

            <div className="grid grid-cols-1 gap-4 mt-6">
                <div className="form-group">
                    <label className='mb-[10px] block text-sm font-medium text-dark' htmlFor="secondHolder">Quer incluir um segundo titular?</label>

                    <div className="flex">
                        <div className="mx-auto w-full">
                            <RadioGroup value={selectedOption} onChange={setSelectedOption}>
                                <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4">
                                    <RadioGroup.Option
                                        key="yes"
                                        value="yes"
                                        className={({ active, checked }) =>
                                            `${active ? 'ring-2 ring-white/60 ring-offset-2' : ''}
                                    ${checked ? 'bg-amco-gold-simple text-white' : 'bg-white border border-stroke dark:border-dark-3'}
                                    relative flex cursor-pointer rounded-lg px-5 py-4 focus:outline-none`
                                        }
                                    >
                                        {({ checked }) => (
                                            <>
                                                <div className="flex w-full items-center justify-between">
                                                    <div className="flex items-center">
                                                        <div className="text-sm">
                                                            <RadioGroup.Label
                                                                as="p"
                                                                className={`font-bold ${checked ? 'text-white' : 'text-gray-900'}`}
                                                            >
                                                                Sim
                                                            </RadioGroup.Label>
                                                            <RadioGroup.Description
                                                                as="span"
                                                                className={`inline ${checked ? 'text-sky-100' : 'text-gray-500'}`}
                                                            >
                                                                <span>Pretendo incluir um segundo titular no processo.</span>
                                                            </RadioGroup.Description>
                                                        </div>
                                                    </div>
                                                    {checked && (
                                                        <div className="shrink-0 text-white">
                                                            <CheckIcon className="h-6 w-6" />
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </RadioGroup.Option>

                                    <RadioGroup.Option
                                        key="no"
                                        value="no"
                                        className={({ active, checked }) =>
                                            `${active ? 'ring-2 ring-white/60 ring-offset-2' : ''}
                                    ${checked ? 'bg-amco-gold-simple text-white' : 'bg-white border border-stroke dark:border-dark-3'}
                                    relative flex cursor-pointer rounded-lg px-5 py-4 focus:outline-none`
                                        }
                                    >
                                        {({ checked }) => (
                                            <>
                                                <div className="flex w-full items-center justify-between">
                                                    <div className="flex items-center">
                                                        <div className="text-sm">
                                                            <RadioGroup.Label
                                                                as="p"
                                                                className={`font-bold ${checked ? 'text-white' : 'text-gray-900'}`}
                                                            >
                                                                Não
                                                            </RadioGroup.Label>
                                                            <RadioGroup.Description
                                                                as="span"
                                                                className={`inline ${checked ? 'text-sky-100' : 'text-gray-500'}`}
                                                            >
                                                                <span>Pretendo avançar sem incluir um segundo titular.</span>
                                                            </RadioGroup.Description>
                                                        </div>
                                                    </div>
                                                    {checked && (
                                                        <div className="shrink-0 text-white">
                                                            <CheckIcon className="h-6 w-6" />
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </RadioGroup.Option>
                                </div>
                            </RadioGroup>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StepOne;
