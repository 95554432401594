import React, { useState } from 'react';
import axios from 'axios';

import { MdArrowBack, MdWhatsapp, MdLocalPhone, MdOutlineEmail } from "react-icons/md";
import logo from "../../assets/logo-gold.svg";
import Contacts from '../Common/Contacts';
import BancoPortugalInfo from '../Common/BancoPortugalInfo';

function NoAccessPage() {
    const [email, setEmail] = useState('');
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.get(`https://api.amco.pt/api/auth/by-email?email=${email}`);
            if (response.status === 200 && response.data) {
                alert('Email found! Sending email with leadToken...');
                sendLeadTokenEmail(response.data);
            }
        } catch (error) {
            console.error('Error fetching lead by email:', error);
            alert('Lead not found or error occurred.');
        }
    };

    const sendLeadTokenEmail = async (leadData) => {
        try {
            await axios.post('https://api.amco.pt/api/send-email', {
                email: leadData.email,
                leadToken: leadData.leadToken,
            });
            alert('Email sent successfully!');
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Failed to send email.');
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-amco">
            <div className="w-full max-w-5xl bg-white shadow-md rounded-lg p-6 grid grid-cols-2 gap-4 p-10">
                <div className="place-content-center content-center items-center self-center">
                    <img
                        className="h-12 w-auto logo-amco"
                        src={logo}
                        alt="AMCO | Intermediários de Crédito"
                    />
                </div>

                <div className="pt-2">
                    <h2 className="text-2xl font-bold text-gray-800 mb-2">Acesso Restrito</h2>
                    <hr></hr>
                    <p className="text-sm text-gray-600 text-left max-w-md mt-5">
                        Lamentamos, não tem permissão para aceder a esta página. Por favor verifique o seu email de forma a obter o link de acesso válido.<br />
                        Caso não encontre o email com essa informação por favor introduza o seu email abaixo.
                    </p>

                    <form onSubmit={handleSubmit} className="flex flex-col space-y-4 items-center justify-center p-4">
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email address"
                            required
                            className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        />
                        <button type="submit" className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
                            Submit
                        </button>
                    </form>
                    <span className="text-xs">Ao submeter está a concordar com a Política de Privacidade...</span>

                    <Contacts />
                    <BancoPortugalInfo />
                </div>
            </div>
        </div>
    )
};

export default NoAccessPage;
